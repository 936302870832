import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Hero, HeroContainer, ImageBorder } from 'styles/globalStyle'
import { Title, Text, Icons, Button } from 'components/theme'
import { BasicPageTypes } from 'types/basicPageTypes'
import SliceSection from 'components/SliceSection'
import SEO from 'components/SEO'

type BasicPageParams = {
  data: BasicPageTypes
}

const BasicPage: FC<BasicPageParams> = ({ data }) => {
  const {
    title_meta,
    description_meta,
    hero_title,
    hero_image,
    hero_text,
    hero_button_text,
    hero_button_link,
    body,
  } = data.prismicBasicPage.data

  return (
    <Layout>
      <SEO title={title_meta.text} description={description_meta.text} />
      <Hero>
        <HeroContainer>
          <div>
            <Title as="h1" size="lg" mb="1.5rem">
              {hero_title.text}
            </Title>
            <Text as="h2" size="xl" fontWeight="400">
              {hero_text.text}
            </Text>
            {hero_button_text.text && (
              <Button
                type="gradient"
                size="xxl"
                mt="3rem"
                to={hero_button_link.url}
              >
                <Icons
                  name="calendar"
                  width="1.25rem"
                  height="1.25rem"
                  mr="1rem"
                />
                {hero_button_text.text}
              </Button>
            )}
          </div>
          <ImageBorder
            image={hero_image.gatsbyImageData}
            alt={hero_image.alt}
          />
        </HeroContainer>
      </Hero>
      {body.map((item, idx) => (
        <SliceSection key={idx} data={item} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String) {
    prismicBasicPage(uid: { eq: $uid }) {
      data {
        ...PrismicBasicPageDataTypeFragment
      }
    }
  }
`

export default BasicPage
